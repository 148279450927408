import { LegacyStack } from "@shopify/polaris";
import {
  AnalyticsActiveCpsCountByDeliveryFrequency,
  AnalyticsActiveSubscribers,
  AnalyticsActiveSubscriptions,
  AnalyticsNewSubscribers,
  AnalyticsNewSubscriptions,
} from "@smartrr/shared/typedVendorApi/analyticsReqResInterfaces";
import React from "react";
import { Loader } from "rsuite";
import styled from "styled-components";

import { RechartsBarChart } from "../RechartsBarChart";
import { RechartsLineChart } from "../RechartsLineChart";

export const ChartContainer = styled.div`
  height: 380px;
  margin-top: 12px;
  position: relative;
  width: 100%;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export interface IMultiTypeChartContainerProps {
  seriesData:
    | AnalyticsActiveSubscriptions[]
    | AnalyticsActiveSubscribers[]
    | AnalyticsNewSubscriptions[]
    | AnalyticsNewSubscribers[]
    | AnalyticsActiveCpsCountByDeliveryFrequency;
  interval?: "week" | "month" | "day";
  isLoading?: boolean;
  minY?: number;
  maxY?: number;
  chartType?: "bar" | "line";
}

export function MultiTypeChartContainer({
  seriesData,
  interval,
  isLoading,
  minY,
  maxY,
  chartType,
}: IMultiTypeChartContainerProps): JSX.Element {
  return (
    <div style={{ position: "relative" }}>
      <ChartContainer>
        {seriesData?.length ? (
          <ChartContainer>
            {chartType === "line" ? (
              <RechartsLineChart
                series={seriesData}
                interval={interval}
                isLoading={isLoading}
                minY={minY}
                maxY={maxY}
              />
            ) : (
              <RechartsBarChart
                series={seriesData}
                interval={interval}
                isLoading={isLoading}
                minY={minY}
                maxY={maxY}
              />
            )}
          </ChartContainer>
        ) : null}
        {isLoading ? (
          <div className={isLoading ? "loading-state-outer-wrapper" : "loaded-state-outer-wrapper"}>
            <LegacyStack alignment="center" distribution="center">
              <Loader />
            </LegacyStack>
          </div>
        ) : null}
      </ChartContainer>
    </div>
  );
}
