import React, { useCallback, useEffect, useState } from "react";
import "react-vis/dist/style.css";
import {
  AnalyticsSubsCountForVntFormattedForFE,
  IAnalyticsSimpleQuery,
  IAnalyticsSimpleRes,
} from "@smartrr/shared/typedVendorApi/analyticsReqResInterfaces";

import { ExportModal } from "@smartrr/vendor-portal/src/app/_sharedComponents/ExportModal";
import { SimpleHeader } from "@smartrr/vendor-portal/src/app/_sharedComponents/TableHeader";
import {
  getActiveSubscribersSeriesData,
  getActiveSubscriptionsSeriesData,
  getNewSubscribersSeriesData,
  getNewSubscriptionsSeriesData,
  trimActiveSubsDataToDates,
  trimNewSubscribersDataToDates,
  trimNewSubscriptionsDataToDates,
} from "@smartrr/vendor-portal/src/utils/analyticsUtils";
import { loadAnalyticsSimple } from "@vendor-app/app/_state/actionCreators/analytics";
import { defaultEnd, defaultStart } from "@vendor-app/app/_state/reducers/analytics";
import { useSmartrrVendorDispatch, useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";

import { ButtonWithDatePicker } from "../../../components/elements/ButtonWithDatePicker";
import {
  IMultiTypeChartContainerProps,
  MultiTypeChartContainer,
} from "../../../components/elements/MultiTypeChartContainer";

import "./index.css";
import { Icon, LegacyCard, Range, Text, Tooltip } from "@shopify/polaris";
import { CalendarMajor, CircleInformationMajor } from "@shopify/polaris-icons";
import { toViewShortDate } from "@smartrr/shared/utils/renderViewDate";
import { toUTCDateLuxon } from "@smartrr/shared/utils/dateUtils";
import { getLocaleDateRoundedToDay } from "@smartrr/shared/utils/dateRounding";

import { RechartsTreeMap } from "../../../components/elements/RechartsTreeMap";
import ChartErrorInfoText from "../../../components/elements/ChartErrorInfoText";

const initialAnalyticsSimpleObj: IAnalyticsSimpleRes = {
  activeSubsOverTime: [],
  newSubscriptionsOverTime: [],
  newSubscribersOverTime: [],
  activeSubsTopVnts: [],
  activeSubsByFreq: [],
  cancelledSubsOverTime: [],
  newOrdersOverTime: [],
};

export function Analytics() {
  const isSuperUser = useSmartrrVendorSelector(state => state.auth.user?.isSuperUser);

  const {
    vendorOrganizations: { activeOrganizationId },
  } = useSmartrrVendorSelector(state => state);

  const analyticsSimple =
    useSmartrrVendorSelector(state => state.analytics.analyticsSimpleResponse) || initialAnalyticsSimpleObj;

  const isLoading = useSmartrrVendorSelector(state => state.analytics.isLoading);

  const [selectedDates, setSelectedDates] = useState<Range>({
    start: defaultStart,
    end: defaultEnd,
  });

  const [showDatePicker, setShowDatePicker] = useState(false);
  const [datesPickedHack, setDatesPickedHack] = useState(false);
  const [aggInterval, setAggInterval] = useState<"day" | "week" | "month">("month");

  const selectedStart = toUTCDateLuxon(selectedDates.start).minus({ days: 2 }).toJSDate();
  const selectedEnd = toUTCDateLuxon(selectedDates.end).plus({ days: 2 }).toJSDate();

  const activeSubsOverTimeTrimmed = trimActiveSubsDataToDates(
    getLocaleDateRoundedToDay(selectedDates.start),
    getLocaleDateRoundedToDay(selectedDates.end),
    analyticsSimple.activeSubsOverTime
  );

  const activeSubscriptionsOverTime: IMultiTypeChartContainerProps = {
    ...getActiveSubscriptionsSeriesData(activeSubsOverTimeTrimmed),
    interval: "day" as const,
    isLoading,
    chartType: "line",
  };

  const activeSubscribersOverTime: IMultiTypeChartContainerProps = {
    ...getActiveSubscribersSeriesData(activeSubsOverTimeTrimmed),
    interval: "day" as const,
    isLoading,
    chartType: "line",
  };

  const newSubscriptionsOverTimeTrimmed = trimNewSubscriptionsDataToDates(
    getLocaleDateRoundedToDay(selectedDates.start),
    getLocaleDateRoundedToDay(selectedDates.end),
    analyticsSimple.newSubscriptionsOverTime
  );

  const newSubscribersOverTimeTrimmed = trimNewSubscribersDataToDates(
    getLocaleDateRoundedToDay(selectedDates.start),
    getLocaleDateRoundedToDay(selectedDates.end),
    analyticsSimple.newSubscribersOverTime
  );

  const newSubscriptionsOverTime: IMultiTypeChartContainerProps = {
    ...getNewSubscriptionsSeriesData(newSubscriptionsOverTimeTrimmed),
    interval: "day" as const,
    isLoading,
    chartType: "bar",
  };

  const newSubscribersOverTime: IMultiTypeChartContainerProps = {
    ...getNewSubscribersSeriesData(newSubscribersOverTimeTrimmed),
    interval: "day" as const,
    isLoading,
    chartType: "bar",
  };

  const activeSubsTopVnts: AnalyticsSubsCountForVntFormattedForFE = analyticsSimple.activeSubsTopVnts;

  const activeCpsCountByFrequency: IMultiTypeChartContainerProps = {
    seriesData: analyticsSimple.activeSubsByFreq,
    isLoading,
    chartType: "bar",
  };

  useEffect(() => {
    if (+selectedDates.end !== +selectedDates.start) {
      setShowDatePicker(false);
      setDatesPickedHack(!datesPickedHack);
    }
  }, [+selectedDates.end]);

  useEffect(() => {
    fetchSimpleAnalytics({
      startDate: selectedStart.toISOString(),
      endDate: selectedEnd.toISOString(),
      tzid: Intl.DateTimeFormat().resolvedOptions().timeZone,
      tzOffset: `${selectedStart.getTimezoneOffset() / 60}`,
      aggInterval,
    });
  }, [datesPickedHack, aggInterval]);

  const dispatch = useSmartrrVendorDispatch();

  const fetchSimpleAnalytics = useCallback(
    async (simpleQuery: IAnalyticsSimpleQuery) => {
      if (!activeOrganizationId) {
        return null;
      }
      await dispatch(loadAnalyticsSimple(simpleQuery));
    },
    [activeOrganizationId]
  );

  if (isSuperUser) {
    console.log("analyticsSimple", analyticsSimple);
  }

  const [isModalActive, setIsModalActive] = useState(false);

  const modalInsteadOfOnExport = useCallback(() => {
    setIsModalActive(true);
  }, []);

  const updateAggInterval = useCallback(newVal => {
    const [interval] = newVal;
    setAggInterval(interval);
  }, []);

  return (
    <div className="analytics-page-outer-wrapper">
      <SimpleHeader
        title="Reports"
        onExport={
          modalInsteadOfOnExport // this is kind of a hack
          // exportToCsvWithLoadingState
        }
        exportIsLoading={isModalActive}
        elements={[
          <ButtonWithDatePicker
            buttonProps={{
              className: "card-section-header-button",
              primary: false,
            }}
            datePickerProps={{
              selected: selectedDates,
              onChange: setSelectedDates,
            }}
            setShowDatePicker={setShowDatePicker}
            showDatePicker={showDatePicker}
            key="z"
            selectedInterval={aggInterval}
            setSelectedInterval={updateAggInterval}
          >
            <div className="button-wrapper">
              <div className="information-icon">
                <Tooltip content="Data from start date to end date, inclusive.">
                  <Icon source={CalendarMajor} color="subdued" />
                </Tooltip>
              </div>
              {selectedDates.start && selectedDates.end ? (
                <Text variant="bodyMd" as="span" fontWeight="semibold">
                  {toViewShortDate(selectedDates.start)}-{toViewShortDate(selectedDates.end)}
                </Text>
              ) : (
                <Text variant="bodyMd" as="span" fontWeight="semibold">
                  Date Range
                </Text>
              )}
            </div>
          </ButtonWithDatePicker>,
        ]}
      />
      <div className="analytics-card-inner-wrapper">
        <LegacyCard sectioned>
          <div>
            <div className="card-section-header-left">
              <div className="card-section-header-text">Active Subscriptions</div>
              <div className="information-icon">
                <Tooltip content="Active subscriptions over time. Paused and expired subscriptions are excluded.">
                  <Icon source={CircleInformationMajor} color="subdued" />
                </Tooltip>
              </div>
            </div>
            {!!activeSubscriptionsOverTime && <MultiTypeChartContainer {...activeSubscriptionsOverTime} />}
          </div>
        </LegacyCard>
        <LegacyCard sectioned>
          <div>
            <div className="card-section-header-left">
              <div className="card-section-header-text">Active Subscribers</div>
              <div className="information-icon">
                <Tooltip
                  content="Customers with at least one active subscription are counted as active subscribers.
                  Paused and expired subscriptions are excluded."
                >
                  <Icon source={CircleInformationMajor} color="subdued" />
                </Tooltip>
              </div>
            </div>
            {!!activeSubscribersOverTime && <MultiTypeChartContainer {...activeSubscribersOverTime} />}
          </div>
        </LegacyCard>
      </div>
      <div className="analytics-card-inner-wrapper">
        <LegacyCard sectioned>
          <div>
            <div className="card-section-header-left">
              <div className="card-section-header-text">New Subscriptions</div>
              <div className="information-icon">
                <Tooltip
                  content="New subscriptions over time, aggregated by the selected interval (defaults to monthly); 
                  a subscription is aggregated into a given month if that subscription's first order was in that month. 
                  Date range must be at least one month."
                >
                  <Icon source={CircleInformationMajor} color="subdued" />
                </Tooltip>
              </div>
            </div>
            {analyticsSimple.newSubscriptionsOverTime.length === 1 ? (
              <ChartErrorInfoText
                text={"Not enough data. Check that aggregation interval length is less than date range."}
              />
            ) : newSubscriptionsOverTime.maxY === 0 ? (
              <ChartErrorInfoText text={"Not enough data. There may be no new subscriptions in range."} />
            ) : (
              <MultiTypeChartContainer {...newSubscriptionsOverTime} />
            )}
          </div>
        </LegacyCard>
        <LegacyCard sectioned>
          <div>
            <div className="card-section-header-left">
              <div className="card-section-header-text">New Subscribers</div>
              <div className="information-icon">
                <Tooltip
                  content="New subscribers over time, aggregated by the selected interval (defaults to monthly). 
                  A subscriber is counted as 'new' on the creation date of that customer's first subscription.
                  Date range must be at least one month."
                >
                  <Icon source={CircleInformationMajor} color="subdued" />
                </Tooltip>
              </div>
            </div>
            {analyticsSimple.newSubscribersOverTime.length === 1 ? (
              <ChartErrorInfoText
                text={"Not enough data. Check that aggregation interval length is less than date range."}
              />
            ) : newSubscribersOverTime.maxY === 0 ? (
              <ChartErrorInfoText text={"Not enough data. There may be no new subscribers in range."} />
            ) : (
              <MultiTypeChartContainer {...newSubscribersOverTime} />
            )}
          </div>
        </LegacyCard>
      </div>
      <div className="analytics-card-inner-wrapper">
        <LegacyCard sectioned>
          <div>
            <div className="card-section-header-left">
              <div className="card-section-header-text">Active Subscription Count by Product-Variant</div>
              <div className="information-icon">
                <Tooltip
                  content="Currently active subscriptions, broken down by product-variant. 
                  A given subscription will be included in the total count
                  for each of its product-variants. 
                  In other words, one subscription may be counted twice in this chart as a whole, 
                  if it is composed of multiple product-variants."
                >
                  <Icon source={CircleInformationMajor} color="subdued" />
                </Tooltip>
              </div>
            </div>
            {activeSubsTopVnts.length > 0 && <RechartsTreeMap isLoading={isLoading} series={activeSubsTopVnts} />}
          </div>
        </LegacyCard>
        <LegacyCard sectioned>
          <div>
            <div className="card-section-header-left">
              <div className="card-section-header-text">Active Subscription Count by Delivery Frequency</div>
              <div className="information-icon">
                <Tooltip content="Currently active subscriptions, broken down by delivery frequency (one delivery frequency per subscription).">
                  <Icon source={CircleInformationMajor} color="subdued" />
                </Tooltip>
              </div>
            </div>
            {!!activeCpsCountByFrequency && <MultiTypeChartContainer {...activeCpsCountByFrequency} />}
          </div>
        </LegacyCard>
      </div>
      <ExportModal active={isModalActive} setActive={setIsModalActive} />
    </div>
  );
}
