import React from "react";
import styled from "styled-components";

interface Props {
  text: string;
}

const FillChartWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 380px;
  justify-content: center;
`;

export default function ChartErrorInfoText({ text }: Props) {
  return (
    <FillChartWrapper>
      <p>{text}</p>
    </FillChartWrapper>
  );
}
