import { ChoiceList, DatePicker, DatePickerProps, Icon, Tooltip } from "@shopify/polaris";
import { CircleInformationMajor } from "@shopify/polaris-icons";
import React, { ReactElement, useCallback, useMemo, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import styled from "styled-components";

import { ButtonWithAnyChildren } from "../ButtonWithAnyChildren";

const ButtonWithDatePickerWrapper = styled.div`
  position: relative;
`;

const DatePickerWrapper = styled.div`
  background: var(--p-color-bg);
  border: 1px solid #d3d3d3;
  border-radius: var(--p-border-radius-2);
  margin-top: 2px;
  min-width: 30rem;
  padding: 1rem;
  position: absolute;
  top: 100%;
  z-index: 2;

  // .Polaris-DatePicker__MonthLayout {
  //   margin-left: 0;
  // }

  // .Polaris-DatePicker__MonthContainer {
  //   margin: 1rem auto !important;
  // }
`;

const DatePickerInnerWrapper = styled.div`
  padding-bottom: 10px;
`;

const ChoiceListWrapper = styled.div`
  border-top: 1px solid #d3d3d3;
  padding-top: 10px;
`;

interface ButtonWithDatePicker {
  datePickerProps: DatePickerProps;
  buttonProps: any;
}

export function ButtonWithDatePicker({
  datePickerProps,
  buttonProps,
  children,
  showDatePicker,
  setShowDatePicker,
  selectedInterval,
  setSelectedInterval,
}: any): ReactElement {
  const { onChange, selected: selectedDates } = datePickerProps;
  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });

  const toggleDatePicker = () => setShowDatePicker((show: boolean) => !show);

  const handleMonthChange = useCallback((month, year) => setDate({ month, year }), []);

  const aggIntevalTitleComponent = useMemo(
    () => (
      <div style={{ display: "flex", flexDirection: "row" }}>
        <p style={{ marginRight: "6px" }}>Aggregation Interval</p>
        <Tooltip
          content={`Only applied to charts that aggregate by time period
        (viz., "New Subscriptions" and "New Subscribers")`}
        >
          <Icon source={CircleInformationMajor} color="subdued" />
        </Tooltip>
      </div>
    ),
    []
  );

  return (
    <OutsideClickHandler onOutsideClick={() => setShowDatePicker(false)}>
      <ButtonWithDatePickerWrapper>
        <ButtonWithAnyChildren {...buttonProps} onClick={toggleDatePicker}>
          {children}
        </ButtonWithAnyChildren>
        {!!showDatePicker && (
          <DatePickerWrapper>
            <DatePickerInnerWrapper>
              <DatePicker
                month={month}
                year={year}
                onChange={onChange}
                onMonthChange={handleMonthChange}
                selected={selectedDates}
                disableDatesAfter={new Date()}
                multiMonth
                allowRange
              />
            </DatePickerInnerWrapper>
            <ChoiceListWrapper>
              <ChoiceList
                title={aggIntevalTitleComponent}
                choices={[
                  { label: "Daily", value: "day" },
                  { label: "Weekly", value: "week" },
                  { label: "Monthly", value: "month" },
                ]}
                selected={selectedInterval}
                onChange={setSelectedInterval}
              />
            </ChoiceListWrapper>
          </DatePickerWrapper>
        )}
      </ButtonWithDatePickerWrapper>
    </OutsideClickHandler>
  );
}
