import { LegacyStack } from "@shopify/polaris";
import {
  AnalyticsSubsCountForVntFormattedForFE,
  RechartsPurchasableActiveSubsCountBaseObj,
  RechartsVntActiveSubsCountBaseObj,
} from "@smartrr/shared/typedVendorApi/analyticsReqResInterfaces";
import React, { useMemo } from "react";
import { ResponsiveContainer, Tooltip, Treemap } from "recharts";
import { Loader } from "rsuite";

import { ChartContainer } from "../MultiTypeChartContainer";

const COLORS = [
  "#73769e",
  "#e89052",
  "#5d93b3",
  "#b35d6d",
  "#b267b5",
  "#77d47a",
  "#cccbe2",
  "#9aa88f",
  "#b37fff",
];
interface TreeMapProps {
  series: AnalyticsSubsCountForVntFormattedForFE;
  interval?: "week" | "month" | "day";
  isLoading?: boolean;
  minY?: number;
  maxY?: number;
}

export function RechartsTreeMap({ series, isLoading }: TreeMapProps): JSX.Element {
  const totalOverall = series?.reduce((acc: number, obj: RechartsPurchasableActiveSubsCountBaseObj) => {
    const childrenTotal = obj?.children?.reduce((acc: number, obj: RechartsVntActiveSubsCountBaseObj) => {
      return acc + obj.activeCpsCount;
    }, 0);
    return acc + childrenTotal;
  }, 0);

  return (
    <ChartContainer
      style={{
        border: "1px solid #D3D3D3",
        padding: "2px",
      }}
    >
      <ResponsiveContainer>
        <Treemap
          width={200}
          height={300}
          data={series}
          nameKey="purchNameAndVntName"
          dataKey="activeCpsCount"
          content={<CustomizedContent totalOverall={totalOverall} />}
        >
          <Tooltip />
        </Treemap>
      </ResponsiveContainer>
      {!!isLoading && (
        <div className={isLoading ? "loading-state-outer-wrapper" : "loaded-state-outer-wrapper"}>
          <LegacyStack alignment="center" distribution="center">
            <Loader />
          </LegacyStack>
        </div>
      )}
    </ChartContainer>
  );
}

const CustomizedContent = (props: any) => {
  const { root, depth, x, y, width, height, index, purchNameAndVntName, purchName, totalOverall } = props;

  const vntName = purchNameAndVntName?.split(" - ")?.[1];
  const colors = COLORS;

  const percentage = useMemo(() => {
    if (purchNameAndVntName) {
      const cur = root?.children?.find(
        (el: any) => el.purchNameAndVntName === purchNameAndVntName
      )?.activeCpsCount;
      return cur / totalOverall;
    } else if (purchName) {
      const cur = root?.children
        ?.find((el: any) => el.purchName === purchName)
        ?.children?.reduce((acc: number, obj: any) => {
          return acc + obj.activeCpsCount;
        }, 0);
      return cur / totalOverall;
    }
  }, [purchName, purchNameAndVntName, root?.children, totalOverall]);

  return (
    <g>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        style={{
          fill: depth < 2 ? colors[Math.floor((index / root?.children?.length) * colors?.length)] : "#ffffff00",
          stroke: "#fff",
          strokeWidth: 2 / (depth + 1e-10),
          strokeOpacity: 1 / (depth + 1e-10),
        }}
      />
      {depth === 2 && (percentage ? percentage > 0.15 : false) ? (
        <text x={x + width / 2} y={y + height / 2 + 7} textAnchor="middle" fill="#fff" fontSize={14}>
          {vntName}
        </text>
      ) : null}
      {depth === 1 && (percentage ? percentage > 0.15 : false) ? (
        <text x={x + 4} y={y + 18} fill="#fff" fontSize={16} fillOpacity={0.9}>
          {purchName}
        </text>
      ) : null}
    </g>
  );
};
