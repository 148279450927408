import { IAnalyticsSimpleQuery } from "@smartrr/shared/typedVendorApi/analyticsReqResInterfaces";

import { SmartrrThunkAction } from "@vendor-app/app/_state/typedVendorRedux";
import { vendorAsyncDispatch } from "@vendor-app/app/_state/vendorAsyncDispatch";
import { typedFrontendVendorApi } from "@vendor-app/utils/typedFrontendVendorApi";

export const loadAnalyticsSimple =
  (
    analyticsSimpleQuery: IAnalyticsSimpleQuery
  ): SmartrrThunkAction<
    "LOADING_ANALYTICS_SIMPLE" | "ERROR_LOADING_ANALYTICS_SIMPLE" | "LOADED_ANALYTICS_SIMPLE"
  > =>
  async dispatch =>
    vendorAsyncDispatch(
      typedFrontendVendorApi.getReq("/analytics-simple", {
        query: analyticsSimpleQuery,
      }),
      () => {
        return dispatch({
          type: "LOADING_ANALYTICS_SIMPLE",
          payload: analyticsSimpleQuery,
        });
      },
      failure => {
        return dispatch({
          type: "ERROR_LOADING_ANALYTICS_SIMPLE",
          payload: {
            errorMessage: failure.message,
          },
        });
      },
      payload => {
        return dispatch({
          type: "LOADED_ANALYTICS_SIMPLE",
          payload,
        });
      }
    );
