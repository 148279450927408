import {
  AnalyticsActiveCpsCountByDeliveryFrequency,
  AnalyticsActiveSubscribers,
  AnalyticsActiveSubscriptions,
  AnalyticsNewSubscribers,
  AnalyticsNewSubscriptions,
} from "@smartrr/shared/typedVendorApi/analyticsReqResInterfaces";
import { toViewShortDate } from "@smartrr/shared/utils/renderViewDate";
import React from "react";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

interface LineChartProps {
  series:
    | AnalyticsActiveSubscriptions[]
    | AnalyticsActiveSubscribers[]
    | AnalyticsNewSubscriptions[]
    | AnalyticsNewSubscribers[]
    | AnalyticsActiveCpsCountByDeliveryFrequency;
  interval?: "week" | "month" | "day";
  isLoading?: boolean;
  minY?: number;
  maxY?: number;
}

export function RechartsLineChart({ series, minY, maxY, isLoading }: LineChartProps): JSX.Element {
  const formatDateForTooltip = (label: number) => toViewShortDate(new Date(label));
  const xTickFormatter = (value: number) => toViewShortDate(new Date(value));

  const yMin = minY ? minY : 0;
  const yMax = maxY ? maxY : 0;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <LineChart
        width={500}
        height={300}
        data={series}
        margin={{
          right: 16,
          left: -10, // https://github.com/recharts/recharts/issues/843
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis reversed tickFormatter={xTickFormatter} dataKey="date" minTickGap={10} height={35} />
        <YAxis type="number" domain={[yMin, yMax]} />
        <Tooltip labelFormatter={formatDateForTooltip} />
        <Legend />
        {!!series?.[0]?.hasOwnProperty("activeSubscriptions") && (
          <Line
            name="Active Subscriptions"
            type="monotone"
            dataKey="activeSubscriptions"
            stroke="#73769e"
            activeDot={{ r: 1 }}
          />
        )}
        {!!series?.[0]?.hasOwnProperty("activeSubscribers") && (
          <Line
            name="Active Subscribers"
            type="monotone"
            dataKey="activeSubscribers"
            stroke="#e89052"
            activeDot={{ r: 1 }}
          />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
}
