import {
  AnalyticsActiveCpsCountByDeliveryFrequency,
  AnalyticsActiveSubscribers,
  AnalyticsActiveSubscriptions,
  AnalyticsNewSubscribers,
  AnalyticsNewSubscriptions,
} from "@smartrr/shared/typedVendorApi/analyticsReqResInterfaces";
import { toViewShortDate } from "@smartrr/shared/utils/renderViewDate";
import React from "react";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

interface BarChartProps {
  series:
    | AnalyticsActiveSubscriptions[]
    | AnalyticsActiveSubscribers[]
    | AnalyticsNewSubscriptions[]
    | AnalyticsNewSubscribers[]
    | AnalyticsActiveCpsCountByDeliveryFrequency;
  interval?: "week" | "month" | "day";
  isLoading?: boolean;
  minY?: number;
  maxY?: number;
}

const BarColors = ["#73769e", "#e89052"];

export function RechartsBarChart({ series, minY, maxY }: BarChartProps): JSX.Element {
  const formatDateForTooltip = (label: number) => toViewShortDate(new Date(label));
  const xTickFormatter = (value: number) => toViewShortDate(new Date(value));

  const yMin = 0; // 0 for bar charts
  const yMax = maxY ? maxY : 0;

  return (
    <ResponsiveContainer width="100%" height="100%">
      <BarChart
        width={500}
        height={300}
        data={series}
        margin={{
          right: 16,
          left: -10, // https://github.com/recharts/recharts/issues/843
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        {series?.[0]?.hasOwnProperty("newSubscriptions") || series?.[0]?.hasOwnProperty("newSubscribers") ? (
          <XAxis
            reversed
            tickFormatter={xTickFormatter}
            dataKey="aggPeriodStartDate"
            minTickGap={10}
            height={35}
          />
        ) : series?.[0]?.hasOwnProperty("frequencyString") ? (
          <XAxis dataKey="frequencyString" minTickGap={10} height={35} />
        ) : null}
        <YAxis type="number" domain={[yMin, yMax]} />
        {series?.[0]?.hasOwnProperty("frequencyString") ? (
          <Tooltip />
        ) : (
          <Tooltip labelFormatter={formatDateForTooltip} />
        )}
        <Legend />
        {!!series?.[0]?.hasOwnProperty("frequencyString") && (
          <Bar name="Active Subscriptions" type="monotone" dataKey="activeCpsCount" fill={BarColors[0]} />
        )}
        {!!series?.[0]?.hasOwnProperty("newSubscriptions") && (
          <Bar name="New Subscriptions" type="monotone" dataKey="newSubscriptions" fill={BarColors[0]} />
        )}
        {!!series?.[0]?.hasOwnProperty("newSubscribers") && (
          <Bar name="New Subscribers" type="monotone" dataKey="newSubscribers" fill={BarColors[1]} />
        )}
      </BarChart>
    </ResponsiveContainer>
  );
}
