import { ChoiceList, DatePicker, LegacyStack, Modal, Range, Scrollable } from "@shopify/polaris";
import { toUTCDateLuxon } from "@smartrr/shared/utils/dateUtils";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import { createAndDownloadCsv } from "@smartrr/vendor-portal/src/utils/createAndDownloadCsv";
import {
  IExportCSVFilters,
  exportPurchaseStateLineItemsToCsvRows,
  exportPurchaseStatesToCsvRows,
} from "@vendor-app/utils/exportPurchaseStatesToCsvRows";

import { useActiveOrganizationSelector } from "../../_state/reducers/organizations";
import {
  usePurchasableVariants,
  useVendorPortalVariantToPurchasableMap,
} from "../../_state/reducers/purchasables";
import { useSmartrrVendorSelector } from "../../_state/typedVendorReduxHooks";
import { useToast } from "../Toast/ToastProvider";

enum DataModelEnum {
  Cps = "CUSTOMER_PURCHASE_STATE",
  Cpsli = "CUSTOMER_PURCHASE_STATE_LINE_ITEM",
}
interface ExportModalProps {
  active: boolean;
  setActive: (arg0: boolean) => void;
}

export function ExportModal({ active, setActive }: ExportModalProps): JSX.Element {
  const [dataStructure, setDataStructure] = useState<DataModelEnum[]>([DataModelEnum.Cps]);
  const isSuperUser = useSmartrrVendorSelector(state => state.auth.user?.isSuperUser);
  const [exportToCSVIsLoading, setExportToCSVIsLoading] = useState<boolean>(false);
  const activeOrg = useActiveOrganizationSelector();
  const { addToast } = useToast();
  // todo: refractor
  const [selectedDates, setSelectedDates] = useState<Range>({
    start: new Date("2010-01-01"),
    end: new Date(),
  });
  const [filterInContent, setFilterInContent] = useState<IExportCSVFilters["filterIn"]>();
  const [allDates, setAllDates] = useState(["all"]);
  const [allVariants, setAllVariants] = useState(["all"]);
  const [selectedVariants, setSelectedVariants] = useState<string[]>([]);
  const shopCurrency = useSmartrrVendorSelector(state => state.shopifyStoreData.shopifyData?.currency);

  useEffect(() => {
    if (allVariants[0] !== "all") {
      setFilterInContent({
        variantId: [...selectedVariants],
      });
    }
  }, [allVariants, selectedVariants]);

  const exportToCsvWithLoadingState = useCallback(async () => {
    if (activeOrg) {
      setExportToCSVIsLoading(true);
      try {
        if (dataStructure[0] === DataModelEnum.Cps) {
          // todo: refactor
          const rows = await exportPurchaseStatesToCsvRows(activeOrg, shopCurrency, {
            startDate: allDates[0] === "all" ? undefined : toUTCDateLuxon(selectedDates.start).toISO()!,
            endDate:
              allDates[0] === "all" ? undefined : toUTCDateLuxon(selectedDates.end).plus({ days: 1 }).toISO()!,
            filterIn: filterInContent,
          });
          rows && createAndDownloadCsv(rows, "subscriptions");
        } else if (dataStructure[0] === DataModelEnum.Cpsli) {
          const rows = await exportPurchaseStateLineItemsToCsvRows(activeOrg, shopCurrency, {
            startDate: allDates[0] === "all" ? undefined : toUTCDateLuxon(selectedDates.start).toISO()!,
            endDate:
              allDates[0] === "all" ? undefined : toUTCDateLuxon(selectedDates.end).plus({ days: 1 }).toISO()!,
            filterIn: filterInContent,
          });
          rows && createAndDownloadCsv(rows, "subscription_line_items");
        }
      } catch (error) {
        if (isSuperUser) {
          console.log(`Error exporting to CSV: ${error}`);
        }
        addToast("Error exporting to CSV");
      } finally {
        setActive(false);
        setExportToCSVIsLoading(false);
      }
    }
  }, [
    activeOrg,
    isSuperUser,
    dataStructure,
    addToast,
    selectedDates.start,
    selectedDates.end,
    setActive,
    filterInContent,
    allDates,
    shopCurrency,
  ]);

  const handleClose = () => {
    setActive(false);
  };

  const handleDataSturctureChange = useCallback((value: DataModelEnum[]) => setDataStructure(value), []);

  const handleExport = useCallback(() => exportToCsvWithLoadingState(), [exportToCsvWithLoadingState]);

  const [{ month, year }, setDate] = useState({
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
  });

  const handleMonthChange = useCallback((month, year) => setDate({ month, year }), []);

  const handleIncludeDateRangeChange = useCallback(value => setAllDates(value), []);

  const variantToPurchasableMap = useVendorPortalVariantToPurchasableMap();
  const variants = usePurchasableVariants();

  const activeVariants = useMemo(
    () =>
      variants
        .filter(vnt => vnt.isActiveInShopify && !vnt.isDraftOrArchived && !!vnt.shopifyId)
        .map(vnt => {
          return {
            label: `${variantToPurchasableMap[vnt.id].purchasableName} - ${vnt.purchasableVariantName}`,
            value: vnt.shopifyId!,
          };
        }),
    [variants, variantToPurchasableMap]
  );

  const handleFilterVariantsChange = useCallback(value => setAllVariants(value), []);
  const handleSelectedVariantsChange = useCallback(value => setSelectedVariants(value), []);

  const purchasableVariantChoiceList = useMemo(
    () =>
      variants
        .filter(vnt => !!vnt.shopifyId)
        .map(vnt => {
          return {
            label: `${variantToPurchasableMap[vnt.id].purchasableName} - ${vnt.purchasableVariantName}`,
            value: vnt.shopifyId!,
          };
        }),
    [variants, variantToPurchasableMap]
  );

  // todo: maybe add a transition animation here
  const renderDatePicker = useCallback(
    isSelected =>
      isSelected && (
        <DatePicker
          month={month}
          year={year}
          onChange={setSelectedDates}
          onMonthChange={handleMonthChange}
          selected={selectedDates}
          disableDatesAfter={new Date()}
          multiMonth
          allowRange
        />
      ),
    [handleMonthChange, setSelectedDates, selectedDates, month, year]
  );

  const renderAllVariantMultiChoice = useCallback(
    isSelected =>
      isSelected && (
        <Scrollable shadow style={{ maxHeight: "200px" }} focusable>
          <ChoiceList
            allowMultiple
            title=""
            choices={purchasableVariantChoiceList}
            selected={selectedVariants}
            onChange={handleSelectedVariantsChange}
          />
        </Scrollable>
      ),
    [selectedVariants, purchasableVariantChoiceList, handleSelectedVariantsChange]
  );

  const renderActiveVariantMultiChoice = useCallback(
    isSelected =>
      isSelected && (
        <Scrollable shadow style={{ maxHeight: "200px" }} focusable>
          <ChoiceList
            allowMultiple
            title=""
            choices={activeVariants}
            selected={selectedVariants}
            onChange={handleSelectedVariantsChange}
          />
        </Scrollable>
      ),
    [selectedVariants, handleSelectedVariantsChange, activeVariants]
  );

  return (
    <div>
      <Modal
        open={active}
        onClose={handleClose}
        title="Export"
        primaryAction={{
          content: "Export",
          onAction: handleExport,
          loading: exportToCSVIsLoading,
        }}
        secondaryActions={[
          {
            content: "Cancel",
            onAction: handleClose,
          },
        ]}
      >
        <Modal.Section>
          <LegacyStack vertical>
            <LegacyStack.Item>
              <ChoiceList
                title="Data structured as"
                choices={[
                  { label: "Subscriptions", value: DataModelEnum.Cps },
                  {
                    label: "Subscription Line Items",
                    value: DataModelEnum.Cpsli,
                  },
                ]}
                selected={dataStructure}
                onChange={handleDataSturctureChange}
              />
            </LegacyStack.Item>
            <LegacyStack.Item>
              <ChoiceList
                title="Variants to include"
                choices={[
                  { label: "All", value: "all" },
                  {
                    label: "Select from active variants",
                    value: "selectFromActive",
                    renderChildren: renderActiveVariantMultiChoice,
                  },
                  {
                    label: "Select from all variants (including archived and draft)",
                    value: "selectFromAll",
                    renderChildren: renderAllVariantMultiChoice,
                  },
                ]}
                selected={allVariants}
                onChange={handleFilterVariantsChange}
              />
            </LegacyStack.Item>
            <LegacyStack.Item>
              <ChoiceList
                title="Date of subscription contract creation"
                choices={[
                  { label: "All", value: "all" },
                  {
                    label: "Select date range",
                    value: "selectDates",
                    renderChildren: renderDatePicker,
                  },
                ]}
                selected={allDates}
                onChange={handleIncludeDateRangeChange}
              />
            </LegacyStack.Item>
          </LegacyStack>
        </Modal.Section>
      </Modal>
    </div>
  );
}
