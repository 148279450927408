import { Layout, Page } from "@shopify/polaris";
import * as React from "react";
import "./index.css";
import styled from "styled-components";

import { Analytics } from "./Analytics";
const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  & .Polaris-Page {
    padding: 0;

    max-width: 100% !important;
    justify-content: center !important;
    display: flex !important;
  }
  & .Polaris-FooterHelp {
    bottom: 0;
    left: 50%;
    position: absolute;
    transform: translate(-50%);
    & .Polaris-FooterHelp__Text {
      display: flex;
      font-size: 14px;
      & span {
        margin-right: 8px;
        & .Polaris-Icon__Svg {
          fill: #00a0ac;
        }
      }
    }
    & a {
      color: #2c6ecb;
    }
  }
`;

export function AdminAnalyticsRoute(): JSX.Element {
  return (
    <React.Fragment>
      <PageWrapper>
        <Page>
          <Layout>
            <Layout.Section>
              <Analytics />
            </Layout.Section>
          </Layout>
        </Page>
      </PageWrapper>
    </React.Fragment>
  );
}
