import {
  AnalyticsActiveSubsOverTime,
  AnalyticsActiveSubscribers,
  AnalyticsActiveSubscriptions,
  AnalyticsNewSubscribers,
  AnalyticsNewSubscribersOverTime,
  AnalyticsNewSubscriptions,
  AnalyticsNewSubscriptionsOverTime,
} from "@smartrr/shared/typedVendorApi/analyticsReqResInterfaces";
import { getLocaleDateRoundedToDay } from "@smartrr/shared/utils/dateRounding";

import { IMultiTypeChartContainerProps } from "@vendor-app/app/AdminRoute/components/elements/MultiTypeChartContainer";

export interface IAnalyticsActiveSubscriptionsSeriesData {
  minSubscriptions: number;
  maxSubscriptions: number;
  lastSubscriptionCount: number;
  activeSubscriptionsSeriesData: AnalyticsActiveSubscriptions[];
}

export interface IAnalyticsActiveSubscribersSeriesData {
  minSubscribers: number;
  maxSubscribers: number;
  lastSubscriberCount: number;
  activeSubscribersSeriesData: AnalyticsActiveSubscribers[];
}

export function trimActiveSubsDataToDates(startDate: Date, endDate: Date, data: AnalyticsActiveSubsOverTime) {
  return data.filter(obj => {
    return new Date(obj.date) >= startDate && getLocaleDateRoundedToDay(new Date(obj.date)) <= endDate;
  });
}

export function trimNewSubscriptionsDataToDates(
  startDate: Date,
  endDate: Date,
  data: AnalyticsNewSubscriptionsOverTime
) {
  return data.filter(obj => {
    return (
      new Date(obj.aggPeriodStartDate) >= startDate &&
      getLocaleDateRoundedToDay(new Date(obj.aggPeriodStartDate)) <= endDate
    );
  });
}

export function trimNewSubscribersDataToDates(
  startDate: Date,
  endDate: Date,
  data: AnalyticsNewSubscribersOverTime
) {
  return data.filter(obj => {
    return (
      new Date(obj.aggPeriodStartDate) >= startDate &&
      getLocaleDateRoundedToDay(new Date(obj.aggPeriodStartDate)) <= endDate
    );
  });
}

export function getActiveSubscriptionsSeriesData(
  activeSubs: AnalyticsActiveSubsOverTime
): IMultiTypeChartContainerProps {
  let minSubscriptions = activeSubs.length ? Infinity : 0;
  let maxSubscriptions = activeSubs.length ? -Infinity : 0;
  let activeSubscriptionsSeriesData: AnalyticsActiveSubscriptions[] = [];

  if (activeSubs.length) {
    activeSubscriptionsSeriesData = activeSubs.map<AnalyticsActiveSubscriptions>((obj, index) => {
      if (+obj.activeSubscriptions < minSubscriptions) {
        minSubscriptions = +obj.activeSubscriptions;
      }
      if (+obj.activeSubscriptions > maxSubscriptions) {
        maxSubscriptions = +obj.activeSubscriptions;
      }
      return { date: obj.date, activeSubscriptions: +obj.activeSubscriptions };
    });
  }

  return {
    seriesData: activeSubscriptionsSeriesData,
    minY: minSubscriptions,
    maxY: maxSubscriptions,
  };
}

export function getActiveSubscribersSeriesData(
  activeSubs: AnalyticsActiveSubsOverTime
): IMultiTypeChartContainerProps {
  let minSubscribers = activeSubs.length ? Infinity : 0;
  let maxSubscribers = activeSubs.length ? -Infinity : 0;
  let activeSubscribersSeriesData: AnalyticsActiveSubscribers[] = [];

  if (activeSubs.length) {
    activeSubscribersSeriesData = activeSubs.map<AnalyticsActiveSubscribers>((obj, index) => {
      if (+obj.activeSubscribers < minSubscribers) {
        minSubscribers = +obj.activeSubscribers;
      }
      if (+obj.activeSubscribers > maxSubscribers) {
        maxSubscribers = +obj.activeSubscribers;
      }
      return { date: obj.date, activeSubscribers: +obj.activeSubscribers };
    });
  }

  return {
    seriesData: activeSubscribersSeriesData,
    minY: minSubscribers,
    maxY: maxSubscribers,
  };
}

export function getNewSubscriptionsSeriesData(
  newSubs: AnalyticsNewSubscriptionsOverTime
): IMultiTypeChartContainerProps {
  let minSubscriptions = newSubs.length ? Infinity : 0;
  let maxSubscriptions = newSubs.length ? -Infinity : 0;
  let newSubscriptionsSeriesData: AnalyticsNewSubscriptions[] = [];

  if (newSubs.length) {
    newSubscriptionsSeriesData = newSubs.map<AnalyticsNewSubscriptions>((obj, index) => {
      if (+obj.newSubscriptions < minSubscriptions) {
        minSubscriptions = +obj.newSubscriptions;
      }
      if (+obj.newSubscriptions > maxSubscriptions) {
        maxSubscriptions = +obj.newSubscriptions;
      }
      return { aggPeriodStartDate: obj.aggPeriodStartDate, newSubscriptions: +obj.newSubscriptions };
    });
  }

  return {
    seriesData: newSubscriptionsSeriesData,
    minY: minSubscriptions,
    maxY: maxSubscriptions,
  };
}

export function getNewSubscribersSeriesData(
  newSubs: AnalyticsNewSubscribersOverTime
): IMultiTypeChartContainerProps {
  let minSubscribers = newSubs.length ? Infinity : 0;
  let maxSubscribers = newSubs.length ? -Infinity : 0;
  let newSubscribersSeriesData: AnalyticsNewSubscribers[] = [];

  if (newSubs.length) {
    newSubscribersSeriesData = newSubs.map<AnalyticsNewSubscribers>((obj, index) => {
      if (+obj.newSubscribers < minSubscribers) {
        minSubscribers = +obj.newSubscribers;
      }
      if (+obj.newSubscribers > maxSubscribers) {
        maxSubscribers = +obj.newSubscribers;
      }
      return { aggPeriodStartDate: obj.aggPeriodStartDate, newSubscribers: +obj.newSubscribers };
    });
  }

  return {
    seriesData: newSubscribersSeriesData,
    minY: minSubscribers,
    maxY: maxSubscribers,
  };
}
